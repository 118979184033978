<template>
    <section class="mt-5 gap-3 grid grid-cols-12">
        <div class="col-start-1 col-span-12" align>
            <RadioButtonGroup :label="$tc('duration_choice', 2)" id="duration_choice" :options="durationOptions" valueProperty="value" displayProperty="label" v-model="input_data.duration_choice" />
        </div>
        <div class="col-span-3" v-show="input_data.duration_choice === 'choice'">
            <Input :label="$tc('start_date', 1)" type="date" id="start_date" v-model="input_data.start_date" />
        </div>
        <div class="col-span-3" v-show="input_data.duration_choice === 'choice'">
            <Input :label="$tc('end_date', 1)" type="date" id="end_date" v-model="input_data.end_date" />
        </div>
    </section>
    <section class="mt-10" v-show="ready">
        <LineChart :chartData="chartData" :chartOptions="chartOptions" :styles="chartStyles" />
    </section>
    <section class="mt-10">
        <Header :title="$tc('past_week', 2)" heading="h4" />
        <LineChart :chartData="weekChartData" :chartOptions="chartOptions" :styles="weekChartStyles" />
    </section>
    {{ getDayName() }}

    <section class="mt-5" v-if="ready">
        <table class="divide-y divide-fibonaki-border">
            <thead>
                <tr>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900 w-1/12">{{ $tc('time', 1) }}</th>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900 w-1/12">{{ $tc('monday', 1) }}</th>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900 w-1/12">{{ $tc('tuesday', 1) }}</th>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900 w-1/12">{{ $tc('wednesday', 1) }}</th>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900 w-1/12">{{ $tc('thursday', 1) }}</th>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900 w-1/12">{{ $tc('friday', 1) }}</th>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900 w-1/12">{{ $tc('saturday', 1) }}</th>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900 w-1/12">{{ $tc('sunday', 1) }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="index in 24" :key="index">
                    <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{{ $filters.intToTime(index - 1) }}</td>
                    <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500" :class="getDayName() === 'monday' ? 'bg-gray-200' : ''">{{ $filters.getHumanPrice(elec_epex_spot_data.last_week_belpex_data.monday[index - 1] ?? noData, 2) }}</td>
                    <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500" :class="getDayName() === 'tuesday' ? 'bg-gray-200' : ''">{{ $filters.getHumanPrice(elec_epex_spot_data.last_week_belpex_data.tuesday[index - 1] ?? noData, 2) }}</td>
                    <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500" :class="getDayName() === 'wednesday' ? 'bg-gray-200' : ''">{{ $filters.getHumanPrice(elec_epex_spot_data.last_week_belpex_data.wednesday[index - 1] ?? noData, 2) }}</td>
                    <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500" :class="getDayName() === 'thursday' ? 'bg-gray-200' : ''">{{ $filters.getHumanPrice(elec_epex_spot_data.last_week_belpex_data.thursday[index - 1] ?? noData, 2) }}</td>
                    <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500" :class="getDayName() === 'friday' ? 'bg-gray-200' : ''">{{ $filters.getHumanPrice(elec_epex_spot_data.last_week_belpex_data.friday[index - 1] ?? noData, 2) }}</td>
                    <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500" :class="getDayName() === 'saturday' ? 'bg-gray-200' : ''">{{ $filters.getHumanPrice(elec_epex_spot_data.last_week_belpex_data.saturday[index - 1] ?? noData, 2) }}</td>
                    <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500" :class="getDayName() === 'sunday' ? 'bg-gray-200' : ''">{{ $filters.getHumanPrice(elec_epex_spot_data.last_week_belpex_data.sunday[index - 1] ?? noData, 2) }}</td>
                </tr>
            </tbody>
        </table>
    </section>
</template>

<script>
    import EnergyDataService from '@/services/EnergyDataService';
    import LineChart from '@/components/chart/LineChart';
    import moment from 'moment';

    export default {
        components: {
            LineChart,
        },
        async created() {
            await this.getChartData(this.input_data);
            this.ready = true;
        },
        data() {
            return {
                input_data: {
                    start_date: this.getToday(1),
                    end_date: this.getToday(),
                    duration_choice: '7',
                },
                ready: false,
                durationOptions: [
                    { label: this.$tc('amount_of_days', 1), value: '1' },
                    { label: this.$tc('amount_of_weeks', 1), value: '7' },
                    { label: this.$tc('amount_of_months', 1), value: '31' },
                    { label: this.$tc('amount_of_months', 3), value: '92' },
                    { label: this.$tc('select_date', 2), value: 'choice' },
                ],
                elec_epex_spot_data: [],
                chartStyles: {
                    height: '300px',
                },
                weekChartStyles: {
                    height: '400px',
                },
                chartOptions: {
                    animation: { duration: 0 },
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: true,
                    },
                    tooltips: {
                        titleFontSize: 24,
                        bodyFontSize: 20,
                    },
                    scales: {
                        xAxes: [
                            {
                                gridLines: { display: false },
                                ticks: {
                                    fontSize: 8,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: false,
                                    callback: function (value) {
                                        return '€' + ' ' + value;
                                    },
                                },
                            },
                        ],
                    },
                },
                noData: Array(24).fill(0),
            };
        },
        computed: {
            chartData() {
                if (this.ready) {
                    return {
                        labels: this.elec_epex_spot_data.belpex_labels,
                        datasets: [
                            {
                                label: 'EPEX Spot',
                                fill: true,
                                backgroundColor: 'hsla(204, 68%, 58%, 0.25)',
                                pointRadius: 0,
                                borderWidth: 1,
                                borderColor: '#4ca3dd',
                                data: this.elec_epex_spot_data.belpex_values,
                            },
                        ],
                    };
                } else return [];
            },
            weekChartData() {
                if (this.ready) {
                    const monday = this.elec_epex_spot_data.last_week_belpex_data.monday ?? [];
                    const tuesday = this.elec_epex_spot_data.last_week_belpex_data.tuesday;
                    const wednesday = this.elec_epex_spot_data.last_week_belpex_data.wednesday;
                    const thursday = this.elec_epex_spot_data.last_week_belpex_data.thursday;
                    const friday = this.elec_epex_spot_data.last_week_belpex_data.friday;
                    const saturday = this.elec_epex_spot_data.last_week_belpex_data.saturday;
                    const sunday = this.elec_epex_spot_data.last_week_belpex_data.sunday;
                    return {
                        labels: this.$store.getters['energy_data/hours_in_day'],
                        datasets: [
                            {
                                label: 'Mo',
                                fill: true,
                                backgroundColor: 'transparent',
                                pointRadius: 1,
                                borderWidth: 2,
                                borderColor: '#003f5c',
                                data: monday,
                            },
                            {
                                label: 'Tue',
                                fill: true,
                                backgroundColor: 'transparent',
                                pointRadius: 1,
                                borderWidth: 2,
                                borderColor: '#10B981',
                                data: tuesday,
                            },
                            {
                                label: 'Wed',
                                fill: true,
                                backgroundColor: 'transparent',
                                pointRadius: 1,
                                borderWidth: 2,
                                borderColor: '#8B5CF6',
                                data: wednesday,
                            },
                            {
                                label: 'Thu',
                                fill: true,
                                backgroundColor: 'transparent',
                                pointRadius: 1,
                                borderWidth: 2,
                                borderColor: '#60A5FA',
                                data: thursday,
                            },
                            {
                                label: 'Fri',
                                fill: true,
                                backgroundColor: 'transparent',
                                pointRadius: 1,
                                borderWidth: 2,
                                borderColor: '#9CA3AF',
                                data: friday,
                            },
                            {
                                label: 'Sat',
                                fill: true,
                                backgroundColor: 'transparent',
                                pointRadius: 1,
                                borderWidth: 2,
                                borderColor: '#EF4444',
                                data: saturday,
                            },
                            {
                                label: 'Sun',
                                fill: true,
                                backgroundColor: 'transparent',
                                pointRadius: 1,
                                borderWidth: 2,
                                borderColor: '#ffa600',
                                data: sunday,
                            },
                        ],
                    };
                } else return [];
            },
        },
        methods: {
            getToday(days) {
                const date = new Date();
                return moment(date).subtract(days, 'days').format('YYYY-MM-DD');
            },
            getDayName() {
                const date = new Date();
                return moment(date).format('dddd').toLowerCase();
            },
            async getChartData(input_data) {
                const elecEpexSpotDataResponse = await EnergyDataService.getElectricityEpexSpotData(input_data);
                this.elec_epex_spot_data = elecEpexSpotDataResponse.data.elec_epex_spot_data;
            },
        },
        watch: {
            'input_data.duration_choice'() {
                this.getChartData(this.input_data);
            },
            'input_data.start_date'() {
                this.getChartData(this.input_data);
            },
            'input_data.end_date'() {
                this.getChartData(this.input_data);
            },
        },
    };
</script>
